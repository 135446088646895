import { Interbank } from '@framework/types/home'
import React, { FC, useState } from 'react'
import SunatNew2 from '../icons/SunatNew2'
import ValueQuotation, { ValueQuotationV2 } from '../ValueQuotation'
import DolarNew from '../icons/DolarNew'
import s from "./Quotation.module.css";
import Link from 'next/link'
import EuroNew from '../icons/EuroNew'


interface QuotationMobileProps {
    exchangeSunat ?: Interbank,
    exchangeDolar : Interbank, 
    exchangeEuro?: Interbank,
    currency: string,
    valueCal: number,
    title: string,
    }

const QuotationMobile: FC<QuotationMobileProps> = ({
    exchangeSunat,
    exchangeDolar,
    exchangeEuro,
    currency,
    valueCal,
    title
}) => {
    const [activeTab, setActiveTab] = useState('Paralelo'); // Estado para controlar la pestaña activa
    
    const handleTabClick = (tab: string) => {
      setActiveTab(tab);
    };

  return (
    <div>  
      <h3 className='font-bold text-lg mt-6'>Cotización</h3>
      <div className='border-b-[bg-#E6E0E9] border-b flex'>
        <div>
          <button className={`mx-4 font-bold text-xs leading-5 pt-3 ${activeTab == 'Paralelo' ? 'text-black' : 'text-[#8E8E8E] '}`} onClick={() => handleTabClick('Paralelo')}>
            Paralelo
          </button>
          {activeTab === 'Paralelo' && <span className={`${s.barra_violeta}`}></span>}
        </div>
        <div>
          <button className={`mx-4 font-bold text-xs leading-5 pt-3 ${activeTab == 'Sunat' ? 'text-black' : 'text-[#8E8E8E] '}`} onClick={() => handleTabClick('Sunat')}>
            Sunat
          </button>
          {activeTab === 'Sunat' && <span className={`${s.barra_violeta}`}></span>}
        </div>
        <div>
          <button className={`mx-4 font-bold text-xs leading-5 pt-3 ${activeTab == 'Euro' ? 'text-black' : 'text-[#8E8E8E] '}`} onClick={() => handleTabClick('Euro')}>
            Euro
          </button>
          {activeTab === 'Euro' && <span className={`${s.barra_violeta}`}></span>}
        </div>
      </div>     
      {exchangeEuro && activeTab === 'Euro' && (
        <div className={`bg-gray1 flex pb-2 pl-2 justify-between`}>
          <div className={`${s.row_cot} py-1 mr-2`}>
            <div className="w-10 my-auto flex">
            <EuroNew fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
            </div>
            <p className="flex items-center text-xl">
              <span>Euro</span>
            </p>          
            </div>
          <div className={`${s.row_cost}`}>
            <ValueQuotationV2 exchangeValue={exchangeEuro} title={title} valueCal={valueCal} currency={currency} type={'sunat'} component='mobile'/>
          </div>
        </div>
        )}  
        {exchangeSunat && activeTab === 'Sunat' && (
        <div className={`bg-gray1 flex pb-2 pl-2 justify-between`}>
          <div className={`${s.row_cot} py-1 mr-2`}>
            <div className="w-10 my-auto">
              <SunatNew2 fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
            </div>
            <p className="flex items-center text-xl">
              <span>Sunat</span>
            </p>   
          </div>
          <div className={`${s.row_cost} `}>
            <ValueQuotationV2 exchangeValue={exchangeSunat} title={title} valueCal={valueCal} currency={currency} type={'sunat'} component='mobile'/>
          </div>
        </div>
        )}
      {exchangeDolar && activeTab === 'Paralelo' && (
        <div className={`bg-gray1 flex pb-2 pl-2 justify-between`}>
          <div className={`${s.row_cot} py-1 mr-2`}>
              <div className="w-10 my-auto">
                <DolarNew fill={title == 'tipo de cambio' ? '#DADADA' : ''}/>
              </div>
              <p className="flex items-center text-xl">
              <span>Paralelo</span>
            </p>   
          </div>
            <div className={`${s.row_cost} `}>
            <ValueQuotationV2 exchangeValue={exchangeDolar} title={title} valueCal={valueCal} currency={currency} type={'dolar'} component='mobile'/>
            </div>
        </div>
        )
      }
      {/* <div className="flex">
        <button className=' w-[157px] flex bg-white justify-center mt-5 md:mt-6 h-[30px] mx-auto items-center text-[#0096A6] rounded-full font-medium shadow-lg md:text-xs text-[10px] hover:text-white border border-[#0096A6] hover:bg-[#0096A6]'>
          <Link
              href={'/calcular'}
              passHref
              >
                <p className=" px-4">
                  CALCULAR MÁS DIVISAS
                </p>
          </Link>
        </button>
        <button className='w-[123px] flex text-white justify-center mt-5 md:mt-6 h-[30px] mx-auto items-center bg-[#0096A6] rounded-full font-medium shadow-lg md:text-xs text-[10px] hover:bg-white hover:text-[#0096A6] hover:border hover:border-[#0096A6]'>
          <Link
              href={'/precio-del-oro'}
              passHref
              >
                <p className=" px-4">
                  PRECIO DE ORO
                </p>
          </Link>
        </button>
      </div> */}
    </div>
  )
}

export default QuotationMobile