import { Interbank } from '@framework/types/home';
import React, { FC, useEffect, useRef, useState } from 'react';
import ArrowNormal from '../icons/ArrowNormal';
import Equals from '../icons/Equals';
import 'animate.css';
import s from './ValueQuotation.module.css';
import Image from 'next/image';

const ArrowUp = () => <span className={s.arrowUp}>&uarr;</span>;
const ArrowDown = () => <span className={s.arrowDown}>&darr;</span>;

export interface ValueQuotationProps {
  exchangeValue: Interbank;
  title: string;
  valueCal: number;
  currency: string;
  type: string;
  section?: string;
  component?: string;
  timestamp?: number;
  convertedTime?: string;
}

export const ValueQuotation: FC<ValueQuotationProps> = ({
  exchangeValue,
  title,
  valueCal,
  currency,
  type,
  section,
  component,
  timestamp,
  convertedTime
}) => {
  // const previousValueRef = useRef<Interbank | null>(null);
  // const [buyRateChange, setBuyRateChange] = useState<'increased' | 'decreased' | 'unchanged'>('unchanged');
  // const [saleRateChange, setSaleRateChange] = useState<'increased' | 'decreased' | 'unchanged'>('unchanged');
  const [buyAnimation, setBuyAnimation] = useState<string>('');
  const [saleAnimation, setSaleAnimation] = useState<string>('');

  // const audioChange = new Audio('/sound/audioChange.mp3');

  // useEffect(() => {
  //   if (previousValueRef.current) {
  //     const previousBuyCost = parseFloat(previousValueRef.current.buy.cost);
  //     const currentBuyCost = parseFloat(exchangeValue.buy.cost);
  //     const previousSaleCost = parseFloat(previousValueRef.current.sale.cost);
  //     const currentSaleCost = parseFloat(exchangeValue.sale.cost);

  //     if (currentBuyCost > previousBuyCost) {
  //       setBuyRateChange('increased');
  //       setBuyAnimation('animate__fadeInUp');
  //       // audioChange.play();
  //     } else if (currentBuyCost < previousBuyCost) {
  //       setBuyRateChange('decreased');
  //       setBuyAnimation('animate__fadeInDown');
  //       // audioChange.play();
  //     } else {
  //       setBuyRateChange('unchanged');
  //       setBuyAnimation('animate__pulse');
  //     }

  //     if (currentSaleCost > previousSaleCost) {
  //       setSaleRateChange('increased');
  //       setSaleAnimation('animate__fadeInUp');
  //       // audioChange.play();
  //     } else if (currentSaleCost < previousSaleCost) {
  //       setSaleRateChange('decreased');
  //       setSaleAnimation('animate__fadeInDown');
  //       // audioChange.play();
  //     } else {
  //       setSaleRateChange('unchanged');
  //       setSaleAnimation('animate__pulse');
  //     }
  //   }
  //   previousValueRef.current = { ...exchangeValue }; 
  // }, [exchangeValue]);

  useEffect(() => {
    if (buyAnimation) {
      const timer = setTimeout(() => setBuyAnimation(''), 1000); 
      return () => clearTimeout(timer);
    }
  }, [buyAnimation]);

  useEffect(() => {
    if (saleAnimation) {
      const timer = setTimeout(() => setSaleAnimation(''), 1000);
      return () => clearTimeout(timer);
    }
  }, [saleAnimation]);

  const resultUSD = (valueCal * parseFloat(exchangeValue?.buy?.cost)).toFixed(2);
  const formattedResultUSD = 'S/' + parseFloat(resultUSD).toLocaleString('en-US');
  const resultPEN = (valueCal / parseFloat(exchangeValue?.sale?.cost)).toFixed(2);
  const formattedResultPEN = '$' + parseFloat(resultPEN).toLocaleString('en-US');

  const valor = (signo: string, variacion: number) =>
  {
    return signo === 'equals' ? (
      <div className='flex justify-center items-center'>
        <Equals width={6} height={4} fill={'#4D4B4B'} />
        <p className={`pl-[6px] text-xs text-[#4D4B4B]`}>0.00</p>
      </div>
    ) : signo === 'negative' ? (
      <div className='flex justify-center items-center bg-[#F9E9E7] rounded-[5px] mx-1'>
        <div className='rotate-180'>
          <ArrowNormal width={8} height={5} fill={title === 'tipo de cambio' ? '#DADADA ' : '#ED1C24'} />
        </div>
        <p className={`${title === 'tipo de cambio' ? 'text-gray' : 'text-[#ED1C24] '} pl-[6px] text-xs`}>{variacion}</p>
      </div>
    ) : (
      <div className='flex justify-center items-center bg-[#E9F4EB] rounded-[5px] mx-1'>
        <ArrowNormal width={8} height={5} fill={title === 'tipo de cambio' ? '#DADADA ' : '#02A702'} />
        <p className={`${title === 'tipo de cambio' ? 'text-gray' : 'text-[#02A702]'} pl-[6px] text-xs`}>{variacion}</p>
      </div>
    );
  }

  const valueStyleSale = () =>{
    if(exchangeValue?.sale?.signal === 'equals'){
      return s.text_equals;
    } else if(exchangeValue?.sale?.signal === 'negative'){
      return s.text_red;
    } else {
      return s.text_green;
    }
  };

  const valueStyleBuy = () => {
    if(exchangeValue?.buy?.signal === 'equals'){
      return s.text_equals;
    } else if(exchangeValue?.buy?.signal === 'negative'){
      return s.text_red;
    } else {
      return s.text_green;
    }
  };
  
  return (
    <div>
      <div
        className={`flex justify-center ${
          title === 'home' && currency !== '' ? 'flex justify-center ' : ''
        } ${component === 'mobile' ? 'w-full' : ''}`}
      >
        <div className='block'>
          {component === 'mobile' && (
            <span className={`content-center text-[10px] ${currency === 'PEN' ? 'hidden' : ''}`}>Compra</span>
          )}
          <div
            className={`${currency === 'PEN' ? 'hidden' : 'block mx-1'} ${
              title === 'home' ? '' : ''
            } block 
              ${valueCal ? 'w-[160px] ' : 'w-[70px]'}
              ${component === 'mobile' ? 'w-full ' : ''}
              `}
          >
            <div className={`${s.valueContainer}`}>
              <p
                className={`animate__animated ${buyAnimation} ${s.text} 
                  ${formattedResultUSD.length > 9 ? 'text-lg' : section ? 'text-xl md:text-lg' : 'text-lg'} ${
                  component === 'mobile' ? 'text-lg md:py-0 md:text-lg' : 'text-lg'
                } ${valueStyleBuy()}`}
              >
                {currency === 'USD' ? formattedResultUSD : parseFloat(exchangeValue?.buy?.cost).toFixed(3)}
              </p>
            </div>
            <span className='w-full'>{valor(exchangeValue?.buy?.signal, exchangeValue?.buy?.variation)}</span>
          </div>
        </div>
        <div className='block'>
          {component === 'mobile' && formattedResultPEN && (
            <span className={`content-center text-[10px] ${currency === 'USD' ? 'hidden' : ''}`}>Venta</span>
          )}
          <div
            className={`${currency === 'USD' ? 'hidden ' : 'block mx-1 '} ${title === 'home' ? ' ' : ''}   
              ${valueCal ? 'w-[160px]' : 'w-[70px]'}
              ${component === 'mobile' ? 'w-full' : ''}`}
          >
            <div className={`${s.valueContainer} `}>
              <p
                className={`animate__animated ${saleAnimation} ${s.text} 
                  ${formattedResultPEN.length > 9 ? 'text-lg md:text-lg ' : section ? 'text-xl md:text-lg' : 'text-lg'} ${
                  component === 'mobile' ? 'text-lg md:py-0 md:text-lg' : 'text-lg'
                } ${valueStyleSale()} `}
              >
                {currency === 'PEN' ? formattedResultPEN : parseFloat(exchangeValue?.sale?.cost).toFixed(3)}
              </p>
            </div>
            <span>{valor(exchangeValue?.sale?.signal, exchangeValue?.sale?.variation)}</span>
          </div>
        </div>
      </div>
      {timestamp && convertedTime && (
        <div className='flex justify-center items-center text-[#5E5757] h-3 mt-0.5'>
            <Image src={`/icons/iconUpdate.svg`} width={7} height={7} layout="fixed" alt="img" />
            <p className='text-[9px] mx-1 '>{convertedTime}</p>
        </div>
      )}
    </div>
  );
};

export default ValueQuotation;
